import React from 'react'
import SbEditable from 'storyblok-react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import { Text, H5 } from '@system'
import classNames from 'classnames'
import font from '@helpers/font'

const { makeResponsiveFontSizes } = font

const useStyle = makeStyles((theme) => ({
  outerContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: '25px 30px',
    margin: '40px 10px',
  },
  instruction: (props) => ({
    color:
      (props.variant === 'blue-background' && theme.palette.text.white) ||
      theme.palette.text.tertiary,
    marginBottom: '20px',
    textTransform: props.variant === 'blue-background' && 'uppercase',
  }),
  button: {
    '& a': {
      display: 'block',
      textAlign: (props) => props.textAlignment || 'center',
    },
  },
}))

const InstructionWithCTA = (props) => {
  const { instruction, cta, variant } = props.blok
  const classes = useStyle({ ...props.blok, variant })

  return (
    <SbEditable content={props.blok}>
      <Box
        className={classNames({
          [classes.outerContainer]: variant === 'blue-background',
        })}
      >
        {instruction && (
          <Text className={classes.instruction}>{instruction}</Text>
        )}
        {cta && cta.length > 0 && (
          <Box className={classes.button}>{renderBloks(cta)}</Box>
        )}
      </Box>
    </SbEditable>
  )
}

export default InstructionWithCTA
